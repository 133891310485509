import { API } from '@/api';
import { formatNumber, renderPrice } from '@/utils/strings';
import { getPastThirtyDays } from '@/utils/time';
import { TIME_PERIODS } from '@/constants/timePeriods';

export type Funnel = {
  leads: string;
  contacts: string;
  bookingsScheduled: string;
  bookingsCompleted: string;
  salesCount: string;
  salesPerLeadCount: string;
  salesValue: string;
};

export async function fetchFunnel(practiceID: number) {
  const [startDate, endDate] = getPastThirtyDays();
  const funnelReport = await API.Reports.fetchFunnelReport(practiceID, {
    timestamp: new Date().toISOString(),
    startDate,
    endDate,
    timePeriod: TIME_PERIODS.ALL,
    filters: [],
    split: [],
  });
  const { revenue } = funnelReport[0].value.sales.total;
  return {
    leads: formatNumber(funnelReport[0].value.leads),
    contacts: formatNumber(funnelReport[0].value.contacts),
    bookingsScheduled: formatNumber(funnelReport[0].value.bookingsScheduled),
    bookingsCompleted: formatNumber(funnelReport[0].value.bookingsCompleted),
    salesCount: formatNumber(funnelReport[0].value.sales.total.count),
    salesPerLeadCount: formatNumber(
      funnelReport[0].value.salesPerLead.total.count
    ),
    salesValue: renderPrice(revenue.value, revenue.currency),
  };
}
