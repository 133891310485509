import { type RevenueReport } from '@witmetrics/api-client';
import OverviewCard from '@/components/OverviewCard';
import Table, { TableBody, TableCell, TableRow } from '@/components/Table';
import { usePracticeCurrency } from '@/store/useStore';
import { formatNumber, renderPrice } from '@/utils/strings';
import SalesTableHead from './SalesTableHead';
import {
  classes,
  exportReport,
  groupByMonth,
  styles,
  sumReportValue,
} from './utils';

export type SalesOverviewCardProps = {
  isFetchingData?: boolean;
  currentReport: RevenueReport | null;
  previousReport: RevenueReport | null;
};

export default function SalesOverviewCard({
  isFetchingData,
  currentReport,
  previousReport,
}: SalesOverviewCardProps) {
  const currency = usePracticeCurrency();

  const renderTableData = (report: RevenueReport) => {
    const groupedData = groupByMonth(report);
    return (
      <>
        <TableRow hover>
          <TableCell className={classes.stickyCell}>
            Patients (Accepted)
          </TableCell>
          {groupedData.map((data, i) => (
            <TableCell key={i} className={classes.cell}>
              {formatNumber(data.treatmentAccepted)}
            </TableCell>
          ))}
          <TableCell className={classes.summaryCell}>
            {formatNumber(sumReportValue(groupedData, 'treatmentAccepted'))}
          </TableCell>
        </TableRow>
        <TableRow hover>
          <TableCell className={classes.stickyCell}>
            Patients (Outstanding)
          </TableCell>
          {groupedData.map((data, i) => (
            <TableCell key={i} className={classes.cell}>
              {formatNumber(data.treatmentOutstanding)}
            </TableCell>
          ))}
          <TableCell className={classes.summaryCell}>
            {formatNumber(sumReportValue(groupedData, 'treatmentOutstanding'))}
          </TableCell>
        </TableRow>
        <TableRow hover>
          <TableCell className={classes.stickyCell}>Revenue</TableCell>
          {groupedData.map((data, i) => (
            <TableCell key={i} className={classes.cell}>
              {renderPrice(data.revenue.value, currency?.value)}
            </TableCell>
          ))}
          <TableCell className={classes.summaryCell}>
            {renderPrice(
              sumReportValue(groupedData, 'revenue'),
              currency?.value
            )}
          </TableCell>
        </TableRow>
      </>
    );
  };
  return (
    <OverviewCard
      isFetchingData={isFetchingData}
      title="Sales Overview"
      subtitle="Patients & sales per month"
      onExport={() => exportReport(currentReport, previousReport)}>
      {currentReport && previousReport && (
        <>
          <Table>
            <SalesTableHead year={2024} />
            <TableBody sx={styles.tableBody}>
              {renderTableData(currentReport)}
              <SalesTableHead row year={2023} />
              {renderTableData(previousReport)}
            </TableBody>
          </Table>
        </>
      )}
    </OverviewCard>
  );
}
