import { type RevenueReport } from '@witmetrics/api-client';
import Chart from '@/components/Chart';
import { usePracticeCurrency } from '@/store/useStore';
import { Currency } from '@/constants/currencies';
import { formatReport } from './utils';

export type RevenueGrowthChartProps = {
  currentReport: RevenueReport;
  previousReport: RevenueReport;
};

export default function RevenueGrowthChart({
  currentReport,
  previousReport,
}: RevenueGrowthChartProps) {
  const currency = usePracticeCurrency();
  const reportData = formatReport(
    currentReport,
    previousReport,
    currency?.value as Currency
  );
  return (
    <Chart
      type="line"
      width="100%"
      height="250px"
      options={reportData.options}
      series={reportData.series}
    />
  );
}
