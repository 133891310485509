import type { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  default as MuiMenu,
  type MenuProps as MuiMenuProps,
} from '@mui/material/Menu';
import MenuItem from './MenuItem';
import MenuDivider from './MenuDivider';
import { classes, styles } from './utils';

export interface MenuProps extends PropsWithChildren {
  open: boolean;
  autoFocus?: boolean;
  className?: string;
  anchorTo: HTMLElement | null;
  anchorOrigin?: MuiMenuProps['anchorOrigin'];
  transformOrigin?: MuiMenuProps['transformOrigin'];
  onClose: () => void;
}

export default function Menu({
  anchorOrigin = { horizontal: 'right', vertical: 'bottom' },
  transformOrigin = { horizontal: 'right', vertical: 'top' },
  className = '',
  ...props
}: MenuProps) {
  if (!props.anchorTo) return null;
  return (
    <MuiMenu
      open={props.open}
      autoFocus={props.autoFocus}
      anchorEl={props.anchorTo}
      onClose={props.onClose}
      sx={styles.menu}
      slotProps={{ paper: { className: twMerge(classes.paper, className) } }}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}>
      <ul className={classes.list}>{props.children}</ul>
    </MuiMenu>
  );
}

export { MenuItem, MenuDivider };
