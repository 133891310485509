'use client';
import { useEffect, useState } from 'react';
import { type RevenueReport } from '@witmetrics/api-client';
import Grid from '@mui/material/Grid';
import OverviewCard from '@/components/OverviewCard';
import GlancesCard from '@/components/GlancesCard';
import FunnelCard from '@/components/FunnelCard';
import SalesOverviewCard from '@/components/SalesOverviewCard';
import TreatmentRevenueCard from '@/components/TreatmentRevenueCard';
import AcceptedPatientsChart from '@/components/AcceptedPatientsChart';
import RevenueGrowthChart from '@/components/RevenueGrowthChart';
import { useActiveAccount } from '@/store/useStore';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import {
  classes,
  COLUMN_SPACING,
  fetchRevenueReport,
  ROW_SPACING,
} from './utils';

export default function Page() {
  const activeAccount = useActiveAccount();
  const { onApiError } = useAppState();
  const [currentReport, setCurrentReport] = useState<RevenueReport | null>(
    null
  );
  const [previousReport, setPreviousReport] = useState<RevenueReport | null>(
    null
  );
  const [isFetchingData, toggleFetchingData] = useToggle(true);

  useEffect(() => {
    if (activeAccount) fetchData();
  }, []);

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      const reports = await fetchRevenueReport(activeAccount!.accountID);
      setCurrentReport(reports[0]);
      setPreviousReport(reports[1]);
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching reports');
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.section}>
        <Grid container rowSpacing={ROW_SPACING} columnSpacing={COLUMN_SPACING}>
          <Grid item xs={12} md={8}>
            <Grid
              container
              rowSpacing={ROW_SPACING}
              columnSpacing={COLUMN_SPACING}>
              <Grid item xs={12}>
                <OverviewCard
                  isFetchingData={isFetchingData}
                  title="Accepted Patients">
                  {currentReport && previousReport && (
                    <AcceptedPatientsChart
                      currentReport={currentReport}
                      previousReport={previousReport}
                    />
                  )}
                </OverviewCard>
              </Grid>
              <Grid item xs={12}>
                <OverviewCard
                  isFetchingData={isFetchingData}
                  title="Revenue & Year-over-Year Growth">
                  {currentReport && previousReport && (
                    <RevenueGrowthChart
                      currentReport={currentReport}
                      previousReport={previousReport}
                    />
                  )}
                </OverviewCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid
              container
              rowSpacing={ROW_SPACING}
              columnSpacing={COLUMN_SPACING}>
              <Grid item xs={12}>
                <GlancesCard />
              </Grid>
              <Grid item xs={12}>
                <FunnelCard />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className={classes.section}>
        <Grid container rowSpacing={ROW_SPACING} columnSpacing={COLUMN_SPACING}>
          <Grid item xs={12}>
            <SalesOverviewCard
              isFetchingData={isFetchingData}
              currentReport={currentReport}
              previousReport={previousReport}
            />
          </Grid>
          <Grid item xs={12}>
            <TreatmentRevenueCard
              isFetchingData={isFetchingData}
              report={currentReport}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
