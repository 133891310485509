import { type PropsWithChildren } from 'react';
import Card from '@/components/Card';
import LoopingEllipsis from '@/components/LoopingEllipsis';
import { classes } from './utils';
import IconButton from '@/components/IconButton';
import DownloadIcon from '@/icons/DownloadIcon';
import Tooltip from '@/components/Tooltip';

export interface OverviewCardProps extends PropsWithChildren {
  isFetchingData?: boolean;
  title: string;
  subtitle?: string | null;
  onExport?: () => void;
}

export default function OverviewCard({
  isFetchingData,
  title,
  subtitle,
  onExport,
  children,
}: OverviewCardProps) {
  return (
    <Card className={classes.card}>
      <div className={classes.titleWrapper}>
        <div>
          <h1 className={classes.title}>{title}</h1>
          {subtitle && <h4 className={classes.subtitle}>{subtitle}</h4>}
        </div>
        {onExport && (
          <Tooltip title="Export">
            <IconButton className={classes.exportIcon} onClick={onExport}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className={classes.body}>
        {isFetchingData ? (
          <div className={classes.loadingMessage}>
            Fetching report
            <LoopingEllipsis />
          </div>
        ) : (
          children
        )}
      </div>
    </Card>
  );
}
