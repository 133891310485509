export const classes = {
  paper: 'rounded-xl',
  list: 'outline-none',
};

export const styles = {
  menu: {
    '& .MuiList-root': {
      minWidth: '120px',
      paddingTop: '0.25rem',
      paddingBottom: '0.25rem',
    },
  },
};
