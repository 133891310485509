import React, { useEffect, useState } from 'react';
import PeopleIcon from '@/icons/PeopleIcon';
import CalendarIcon from '@/icons/CalendarIcon';
import TreatmentsIcon from '@/icons/TreatmentsIcon';
import RevenueIcon from '@/icons/RevenueIcon';
import Grid from '@mui/material/Grid';
import OverviewCard from '@/components/OverviewCard';
import ReportBlock from '@/components/ReportBlock';
import { useActiveAccount, usePracticeCurrency } from '@/store/useStore';
import { useAppState } from '@/providers/AppStateProvider';
import { BLANK_GLANCES, classes, fetchGlances, Glances } from './utils';

export default function GlancesCard() {
  const { onApiError } = useAppState();
  const currency = usePracticeCurrency();
  const activeAccount = useActiveAccount();
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [glances, setGlances] = useState<Glances>({ ...BLANK_GLANCES });

  useEffect(() => {
    setGlances({ ...BLANK_GLANCES });
    if (activeAccount) fetchData();
  }, [activeAccount]);

  const fetchData = async () => {
    try {
      setIsFetchingData(true);
      const glancesReports = await fetchGlances(
        activeAccount!.accountID,
        currency?.value
      );
      setGlances(glancesReports);
      setIsFetchingData(false);
    } catch (err) {
      setIsFetchingData(false);
      onApiError(err, 'Error fetching reports');
    }
  };

  return (
    <OverviewCard title="Glances" subtitle="Past 30 days">
      <div className={classes.wrapper}>
        <Grid
          container
          className={classes.gridContainer}
          rowSpacing={3}
          columnSpacing={3}>
          <Grid item xs={6}>
            <ReportBlock
              isFetchingData={isFetchingData}
              label="Leads"
              amount={glances.leads?.current}
              difference={glances.leads?.difference}
              sign={glances.leads?.sign}
              iconClasses={classes.peopleIcon}
              // @ts-ignore
              icon={<PeopleIcon color="white" fontSize="medium" />}
            />
          </Grid>
          <Grid item xs={6}>
            <ReportBlock
              isFetchingData={isFetchingData}
              label="Bookings"
              amount={glances.bookings?.current}
              difference={glances.bookings?.difference}
              sign={glances.bookings?.sign}
              iconClasses={classes.bookingsIcon}
              // @ts-ignore
              icon={<CalendarIcon color="white" fontSize="medium" />}
            />
          </Grid>
          <Grid item xs={6}>
            <ReportBlock
              isFetchingData={isFetchingData}
              label="Treatment Plans"
              labelSize="sm"
              amount={glances.acceptedTreatmentPlans?.current}
              difference={glances.acceptedTreatmentPlans?.difference}
              sign={glances.acceptedTreatmentPlans?.sign}
              iconClasses={classes.treatmentsIcon}
              // @ts-ignore
              icon={<TreatmentsIcon color="white" fontSize="medium" />}
            />
          </Grid>
          <Grid item xs={6}>
            <ReportBlock
              isFetchingData={isFetchingData}
              label="Revenue"
              amount={glances.revenue?.current}
              difference={glances.revenue?.difference}
              sign={glances.revenue?.sign}
              iconClasses={classes.revenueIcon}
              // @ts-ignore
              icon={<RevenueIcon color="white" fontSize="large" />}
            />
          </Grid>
        </Grid>
      </div>
    </OverviewCard>
  );
}
