// Inspired by https://gist.github.com/ahtcx/0cd94e62691f539160b32ecda18af3d6?permalink_comment_id=4594127#gistcomment-4594127
export function deepMerge<T extends object, U extends object>(
  obj1: T,
  obj2: U
): T & U {
  let result = { ...obj1, ...obj2 } as T & U;
  for (const key of Object.keys(result) as (keyof (T & U))[]) {
    result[key] =
      typeof (obj1 as any)[key] === 'object' &&
      typeof (obj2 as any)[key] === 'object'
        ? deepMerge((obj1 as any)[key], (obj2 as any)[key])
        : result[key];
  }
  return result;
}
