import { renderMonthWithYear } from '@/utils/time';
import { theme } from '@/utils/theme';
import { type RevenueReport } from '@witmetrics/api-client';
import { Currency } from '@/constants/currencies';
import { formatPercentage, renderPrice } from '@/utils/strings';

export function formatReport(
  currentReport: RevenueReport,
  previousReport: RevenueReport,
  currency?: Currency
) {
  return {
    series: [
      {
        name: 'Revenue',
        type: 'column',
        data: currentReport.data.map((d) => Math.round(d.leads.revenue.value)),
      },
      {
        name: 'YoY Growth (%)',
        type: 'line',
        data: previousReport.data.map(
          (d, i) =>
            (currentReport.data[i].leads.revenue.value -
              d.leads.revenue.value) /
            d.leads.revenue.value
        ),
      },
    ],
    options: {
      markers: {
        size: 6,
        strokeWidth: 1,
        hover: {
          size: undefined,
          sizeOffset: 0,
        },
      },
      colors: [theme.palette['purple-3'].main, theme.palette['blue-2'].main],
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: { show: false },
      },
      stroke: {
        width: [0, 1],
      },
      plotOptions: {
        line: {
          borderRadius: 2,
        },
      },
      xaxis: {
        categories: currentReport.data.map((d) =>
          renderMonthWithYear(d.startDate, "''yy")
        ),
      },
      yaxis: [
        {
          title: {
            text: 'Revenue',
          },
          labels: {
            formatter: (val: number) => renderPrice(val, currency),
          },
        },
        {
          opposite: true,
          title: {
            text: 'YoY Growth (%)',
          },
          labels: {
            formatter: (val: number) => formatPercentage(val, 1),
          },
        },
      ],
    },
  };
}
