import {
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
} from '@/components/Table';
import { classes } from './utils';

export type SalesTableHeadProps = {
  year: number;
  row?: boolean;
};

const columns = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
  'Total',
];
export default function SalesTableHead({ year, row }: SalesTableHeadProps) {
  if (row) {
    return (
      <TableRow>
        <TableHeadCell className={classes.stickyCell}>{year}</TableHeadCell>
        {columns.map((column) => (
          <TableHeadCell key={column}>{column}</TableHeadCell>
        ))}
      </TableRow>
    );
  }
  return (
    <TableHead>
      <TableHeadRow>
        <TableHeadCell className={classes.stickyCell}>{year}</TableHeadCell>
        {columns.map((column) => (
          <TableHeadCell key={column}>{column}</TableHeadCell>
        ))}
      </TableHeadRow>
    </TableHead>
  );
}
