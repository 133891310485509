import { useEffect, useState } from 'react';
import EmptyMessage from './EmptyMessage';
import FunnelTable from './FunnelTable';
import OverviewCard from '@/components/OverviewCard';
import { useActiveAccount } from '@/store/useStore';
import { useToggle } from '@/hooks/useToggle';
import { useAppState } from '@/providers/AppStateProvider';
import { fetchFunnel, type Funnel } from './utils';

export default function FunnelCard() {
  const activeAccount = useActiveAccount();
  const { onApiError } = useAppState();
  const [funnelReport, setFunnelReport] = useState<Funnel | null>(null);
  const [isFetchingData, toggleFetchingData] = useToggle(true);

  useEffect(() => {
    setFunnelReport(null);
    if (activeAccount) fetchData();
  }, []);

  const fetchData = async () => {
    try {
      toggleFetchingData(true);
      const report = await fetchFunnel(activeAccount!.accountID);
      setFunnelReport(report);
      toggleFetchingData(false);
    } catch (err) {
      toggleFetchingData(false);
      onApiError(err, 'Error fetching Funnel report', () => fetchData());
    }
  };

  return (
    <OverviewCard title="Sales Funnel" subtitle="Leads from the past 30 days">
      {isFetchingData || funnelReport !== null ? (
        <FunnelTable
          isFetchingData={isFetchingData}
          funnelReport={funnelReport}
        />
      ) : (
        <EmptyMessage>Funnel report not currently available.</EmptyMessage>
      )}
    </OverviewCard>
  );
}
