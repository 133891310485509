import { API } from '@/api';
import type {
  GlancesReportResponse,
  RevenueGlancesReportResponse,
} from '@witmetrics/api-client';
import { formatNumber, formatPercentage, renderPrice } from '@/utils/strings';

type GlanceReport = {
  current: string;
  previous: string;
  difference: string;
  sign: number;
};

export type Glances = {
  leads: GlanceReport | null;
  bookings: GlanceReport | null;
  acceptedTreatmentPlans: GlanceReport | null;
  revenue: GlanceReport | null;
};

export const classes = {
  wrapper: 'flex justify-between max-w-[800px] px-6 py-8',
  gridContainer: 'max-w-[800px]',
  peopleIcon: 'bg-purple-2',
  bookingsIcon: 'bg-pink-2',
  treatmentsIcon: 'bg-cyan-2',
  revenueIcon: 'bg-green-2',
};

export const BLANK_GLANCES = {
  leads: null,
  bookings: null,
  acceptedTreatmentPlans: null,
  revenue: null,
};

export async function fetchGlances(practiceID: number, currency?: string) {
  const [leadCounts, bookingCounts, treatmentCounts, revenueCounts] =
    await Promise.all([
      API.Reports.fetchLeadGlancesReport(practiceID),
      API.Reports.fetchBookingGlancesReport(practiceID),
      API.Reports.fetchAcceptedTreatmentPlansGlancesReport(practiceID),
      API.Reports.fetchRevenueGlancesReport(practiceID),
    ]);
  return {
    leads: formatCountReport(leadCounts),
    bookings: formatCountReport(bookingCounts),
    acceptedTreatmentPlans: formatCountReport(treatmentCounts),
    revenue: formatRevenueGlanceReport(revenueCounts, currency),
  };
}

function calculateChange(val1: number, val2: number) {
  return (val2 - val1) / Math.abs(val1 === 0 ? 1 : val1);
}

function formatCountReport(counts: GlancesReportResponse): GlanceReport {
  return {
    current: formatNumber(counts[0].value.count),
    previous: formatNumber(counts[1].value.count),
    ...getChange(counts[0].value.count, counts[1].value.count),
  };
}

function formatRevenueGlanceReport(
  counts: RevenueGlancesReportResponse,
  currency?: string
): GlanceReport {
  return {
    current: renderPrice(counts[0].value.sum.value, currency, {
      useRounding: true,
    }),
    previous: renderPrice(counts[1].value.sum.value, currency, {
      useRounding: true,
    }),
    ...getChange(counts[0].value.sum.value, counts[1].value.sum.value),
  };
}

function getChange(current: number, previous: number) {
  const hasChange = previous > 0 && current !== previous;
  return {
    difference: hasChange
      ? formatPercentage(calculateChange(previous, current), 0)
      : '(not set)',
    sign: hasChange ? Math.sign(current - previous) : 0,
  };
}
