import {
  Day,
  differenceInDays,
  differenceInMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  isPast as dateIsPast,
  isSameWeek as dateIsSameWeek,
  isToday as dateIsToday,
  isTomorrow as dateIsTomorrow,
  isYesterday as dateIsYesterday,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';
import { formatNumber, padNumber } from './strings';
import { TIME_PERIODS, type TimePeriod } from '@/constants/timePeriods';
import { DDMM, MMDD } from '@/constants/dateFormatPreference';
import { DateRange } from '@/types/dates';

export function buildHoursArray(is24Hour = false) {
  let start = is24Hour ? 0 : 1;
  let end = is24Hour ? 24 : 13;
  let result = [];
  for (let i = start; i < end; i++) {
    result.push({ value: i, label: padNumber(i) });
  }
  return result;
}

export function buildMinutesArray(interval = 1) {
  let result = [];
  for (let i = 0; i <= 59; i += interval) {
    result.push({ value: i, label: padNumber(i) });
  }
  return result;
}

export const isToday = (ISODateString: string) =>
  dateIsToday(parseISO(ISODateString));

export const isTomorrow = (ISODateString: string) =>
  dateIsTomorrow(parseISO(ISODateString));

export const isYesterday = (ISODateString: string) =>
  dateIsYesterday(parseISO(ISODateString));

export const isThisWeek = (ISODateString: string, weekStartsOn: Day = 0) =>
  dateIsSameWeek(parseISO(ISODateString), new Date(), { weekStartsOn });

export const isPast = (ISODateString: string) =>
  dateIsPast(parseISO(ISODateString));

export function renderDuration(
  durationInSeconds: number,
  includeSeconds = false
) {
  const { days, hours, minutes, seconds } = splitSeconds(durationInSeconds);
  let result = [];
  if (days !== 0) result.push(`${formatNumber(days)}d`);
  if (hours !== 0) result.push(`${formatNumber(hours)}h`);
  if (minutes !== 0) result.push(`${formatNumber(minutes)}m`);
  if (seconds !== 0 && includeSeconds) {
    result.push(`${formatNumber(seconds)}s`);
  }
  return result.join(' ');
}

export function renderDate(ISODateString: string, dateFormat = MMDD) {
  const formatStr = dateFormat === DDMM ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
  return format(parseISO(ISODateString), formatStr);
}

export function renderTextDate(ISODateString: string, dateFormat = MMDD) {
  const formatStr = dateFormat === DDMM ? 'dd MMM yyyy' : 'MMM dd yyyy';
  return format(parseISO(ISODateString), formatStr);
}

export function renderDateWithTime(ISODateString: string, dateFormat = MMDD) {
  const formatStr = `${
    dateFormat === DDMM ? 'dd MMM yyyy' : 'MMM dd yyyy'
  }, hh:mma`;
  return format(parseISO(ISODateString), formatStr);
}

export function renderDateOnlyTime(ISODateString: string) {
  return format(parseISO(ISODateString), 'hh:mma');
}

export function renderRelativeDate(
  ISODateString: string,
  options?: {
    todayAsTime?: boolean;
    includeTime?: boolean;
    dateFormat?: string | null;
  }
) {
  const todayAsTime = options?.todayAsTime || false;
  const includeTime = options?.todayAsTime || false;
  const dateFormat = options?.dateFormat || MMDD;
  if (isToday(ISODateString)) {
    return todayAsTime ? renderDateOnlyTime(ISODateString) : 'Today';
  } else if (isTomorrow(ISODateString)) {
    return 'Tomorrow';
  } else if (isYesterday(ISODateString)) {
    return 'Yesterday';
  }
  return includeTime
    ? renderDateWithTime(ISODateString, dateFormat)
    : renderTextDate(ISODateString, dateFormat);
}

export function getTimeOfDayMs() {
  const now = Date.now();
  let midnight = new Date();
  midnight.setHours(0, 0, 0);
  return now - midnight.valueOf();
}

export function formatTimeLabel(
  date: string,
  timePeriod: TimePeriod,
  dateFormat = MMDD
) {
  if (timePeriod === TIME_PERIODS.YEAR) {
    return renderYear(date);
  } else if (timePeriod === TIME_PERIODS.MONTH) {
    return renderMonthWithYear(date);
  }
  return renderTextDate(date, dateFormat);
}

export function renderMonthWithYear(
  ISODateString: string,
  yearFormat = 'yyyy'
) {
  return format(parseISO(ISODateString), `MMM ${yearFormat}`);
}

export function renderYear(ISODateString: string) {
  return format(parseISO(ISODateString), 'yyyy');
}

export function buildExportTimestamp(startDate?: string, endDate?: string) {
  if (!startDate) return format(new Date(), 'yyyyMMdd-HHmmss');
  const start = format(new Date(startDate), 'yyyyMMdd');
  if (!endDate) return start;
  return `${start}-${format(new Date(endDate), 'yyyyMMdd')}`;
}

// Timeframe methods

function formatTimeframe(start: Date, end: Date): DateRange {
  return [start.toISOString(), end.toISOString()];
}

function formatEndDate(endDate?: Date) {
  // Defaults to today if no date is provided
  return endDate ? new Date(endDate) : new Date();
}

export function getToday() {
  const date = new Date();
  return formatTimeframe(startOfDay(date), endOfDay(date));
}

export function getYesterday() {
  const date = subDays(new Date(), 1);
  return formatTimeframe(startOfDay(date), endOfDay(date));
}

export function getThisWeek(weekStartsOn: Day = 0) {
  const date = new Date();
  return formatTimeframe(
    startOfWeek(date, { weekStartsOn }),
    endOfWeek(date, { weekStartsOn })
  );
}

export function getLastWeek(weekStartsOn: Day = 0) {
  const date = subWeeks(new Date(), 1);
  return formatTimeframe(
    startOfWeek(date, { weekStartsOn }),
    endOfWeek(date, { weekStartsOn })
  );
}

export function getPastSevenDays(endDate?: Date) {
  const date = formatEndDate(endDate);
  return formatTimeframe(startOfDay(subDays(date, 7)), endOfDay(date));
}

export function getMonthToDate(endDate?: Date) {
  const date = formatEndDate(endDate);
  return formatTimeframe(startOfMonth(date), endOfDay(date));
}

export function getLastMonth(endDate?: Date) {
  const date = subMonths(formatEndDate(endDate), 1);
  return formatTimeframe(startOfMonth(date), endOfMonth(date));
}

export function getPastThirtyDays(endDate?: Date) {
  const date = formatEndDate(endDate);
  return formatTimeframe(startOfDay(subDays(date, 30)), endOfDay(date));
}

export function getPastNinetyDays(endDate?: Date) {
  const date = formatEndDate(endDate);
  return formatTimeframe(startOfDay(subDays(date, 90)), endOfDay(date));
}

export function getYearToDate(endDate?: Date) {
  const date = formatEndDate(endDate);
  return formatTimeframe(startOfYear(date), endOfDay(date));
}

export function getLastYear(endDate?: Date) {
  const date = subYears(formatEndDate(endDate), 1);
  return formatTimeframe(startOfYear(date), endOfYear(date));
}

export function getDefaultTimePeriod(start: string, end: string) {
  const days = Math.abs(differenceInDays(new Date(end), new Date(start)));
  const months = Math.abs(differenceInMonths(new Date(end), new Date(start)));
  if (days <= 31) {
    return TIME_PERIODS.DAY;
  } else if (months <= 4) {
    return TIME_PERIODS.WEEK;
  } else if (months <= 24) {
    return TIME_PERIODS.MONTH;
  } else return TIME_PERIODS.YEAR;
}

export function isTimeframeChanged(
  timeframe1: DateRange,
  timeframe2: DateRange
) {
  return timeframe1[0] !== timeframe2[0] || timeframe1[1] !== timeframe2[1];
}

export function splitSeconds(timeInSeconds: number) {
  const minutes = Math.floor(timeInSeconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  return {
    days,
    hours: hours % 24,
    minutes: minutes % 60,
    seconds: timeInSeconds % 60,
  };
}
