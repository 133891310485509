'use client';
import dynamic from 'next/dynamic';
import { deepMerge } from '@/utils/objects';
import { DEFAULT_CHART_OPTIONS } from './utils';

// NOTE: react-apexcharts requires access to `window`, which throws off next.js compiling
const ApexChart = dynamic(() => import('react-apexcharts'), {
  ssr: false,
});

export default function Chart({ options = {}, ...props }) {
  return (
    <ApexChart options={deepMerge(DEFAULT_CHART_OPTIONS, options)} {...props} />
  );
}
