export const classes = {
  table: 'max-h-[400px]',
  row: 'text-right',
  cell: 'py-3',
};

export const ROWS = [
  { id: 'leads', label: 'Leads' },
  { id: 'contacts', label: 'Contacts' },
  { id: 'bookingsScheduled', label: 'Bookings Scheduled' },
  { id: 'bookingsCompleted', label: 'Bookings Completed' },
  { id: 'salesCount', label: 'Sales Count' },
  { id: 'salesPerLeadCount', label: 'Sales per Lead' },
  { id: 'salesValue', label: 'Sales Revenue' },
] as const;
