import { type PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { classes } from './utils';

export interface EmptyMessageProps extends PropsWithChildren {
  className?: string;
}

export default function EmptyMessage({
  className = '',
  ...props
}: EmptyMessageProps) {
  return <div className={twMerge(classes.wrapper, className)} {...props} />;
}
