import { type RevenueReport } from '@witmetrics/api-client';
import { Dictionary } from '@/types';
import { theme } from '@/utils/theme';
import { sortAlphabeticalByKey } from '@/utils/arrays';
import { exportArraysToSpreadsheet } from '@/utils/spreadsheets';
import { buildExportTimestamp } from '@/utils/time';
import { isBlank } from '@/utils/strings';

export const classes = {
  wrapper: 'flex justify-between max-w-[800px] px-6 py-8',
  stickyCell: 'py-2 sticky left-0 bg-white border-r border-grey-3',
  cell: 'py-2',
  summaryCell: 'py-2 font-semibold text-grey-8',
  table: 'max-h-[800px]',
};

export const styles = {
  tableBody: {
    '& .MuiTableRow-root:last-child td.sticky': {
      borderRight: `1px solid ${theme.palette['grey-3'].main}`,
    },
    '& .MuiTableRow-hover:hover': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(2):hover) tr > *:nth-of-type(2)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(3):hover) tr > *:nth-of-type(3)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(4):hover) tr > *:nth-of-type(4)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(5):hover) tr > *:nth-of-type(5)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(6):hover) tr > *:nth-of-type(6)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(7):hover) tr > *:nth-of-type(7)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(8):hover) tr > *:nth-of-type(8)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(9):hover) tr > *:nth-of-type(9)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(10):hover) tr > *:nth-of-type(10)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(11):hover) tr > *:nth-of-type(11)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(12):hover) tr > *:nth-of-type(12)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(13):hover) tr > *:nth-of-type(13)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(14):hover) tr > *:nth-of-type(14)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
  },
};

type SummedTreatment = {
  id: number;
  name: string;
  description?: string;
  count: Dictionary<number>;
  revenue: Dictionary<number>;
};

type TreatmentPerformance = {
  id: number;
  name: string;
  description?: string;
  count: number[];
  revenue: number[];
};

export function formatTreatmentLabel(treatment: {
  name: string;
  description?: string;
}) {
  return isBlank(treatment.description)
    ? treatment.name
    : `${treatment.name} (${treatment.description})`;
}

export function groupTreatmentByMonth(
  report: RevenueReport | null
): TreatmentPerformance[] {
  if (!report) return [];
  let result: Dictionary<SummedTreatment> = {};
  report.data.forEach(({ startDate, treatments }) => {
    const month = `${new Date(startDate).getMonth()}`;
    treatments.forEach((treatment) => {
      const key = `${treatment.name}.${treatment.description}`;
      if (!result[key]) {
        result[key] = {
          id: treatment.id,
          name: treatment.name,
          description: treatment.description,
          count: { [month]: treatment.count },
          revenue: { [month]: treatment.revenue.value },
        };
      } else {
        if (!result[key].count[month]) {
          result[key].count[month] = treatment.count;
        } else {
          result[key].count[month] += treatment.count;
        }
        if (!result[key].revenue[month]) {
          result[key].revenue[month] = treatment.revenue.value;
        } else {
          result[key].revenue[month] += treatment.revenue.value;
        }
      }
    });
  });
  return sortAlphabeticalByKey(
    Object.values(result).map((t) => ({
      ...t,
      count: Object.values(t.count),
      revenue: Object.values(t.revenue),
    })),
    'name'
  );
}

export function sumValues(values: number[]) {
  return values.reduce((a, b) => a + b, 0);
}

export function sumValuesByMonth(
  report: TreatmentPerformance[],
  key: 'count' | 'revenue'
) {
  let result: Dictionary<number> = {};
  report.forEach((treatment) => {
    treatment[key].forEach((value, i) => {
      if (!result[i]) {
        result[i] = value;
      } else {
        result[i] += value;
      }
    });
  });
  return Object.values(result);
}

export function exportReport(report: RevenueReport | null) {
  if (!report) return null;
  const groupedData = groupTreatmentByMonth(report);
  const totalRevenue = sumValuesByMonth(groupedData, 'revenue');
  const totalCount = sumValuesByMonth(groupedData, 'count');
  return exportArraysToSpreadsheet({
    data: [
      buildTitleRow(2024),
      ...groupedData.map((treatment) => [
        formatTreatmentLabel(treatment),
        ...treatment.revenue,
        sumValues(treatment.revenue),
      ]),
      ['Total', ...totalRevenue, sumValues(totalRevenue)],
      [],
      buildTitleRow(2024),
      ...groupedData.map((treatment) => [
        formatTreatmentLabel(treatment),
        ...treatment.count,
        sumValues(treatment.count),
      ]),
      ['Total', ...totalCount, sumValues(totalCount)],
    ],
    sheetName: 'Treatments Overview',
    filename: `witmetrics-treatments_overview_${buildExportTimestamp()}`,
  });
}

function buildTitleRow(year: number) {
  return [
    `${year}`,
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Total',
  ];
}
