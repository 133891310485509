export const classes = {
  card: 'flex-col px-0 pt-4 pb-0',
  titleWrapper: 'flex justify-between items-start px-6 mb-3',
  title: 'text-grey-8 mb-1 text-xl font-semibold',
  subtitle: 'text-grey-7 text-sm font-normal',
  exportIcon: 'text-grey-6 mr-3',
  body: 'border-t-2 border-grey-3 overflow-x-auto',
  loadingMessage:
    'h-full w-full flex justify-center items-center text-2xl font-light text-grey-6 p-8',
};
