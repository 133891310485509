import Table, {
  SkeletonRow,
  TableBody,
  TableCell,
  TableRow,
} from '@/components/Table';
import { type Funnel } from '../utils';
import { classes, ROWS } from './utils';

export type FunnelTableProps = {
  isFetchingData?: boolean;
  funnelReport: Funnel | null;
};

export default function FunnelTable({
  isFetchingData,
  funnelReport,
}: FunnelTableProps) {
  return (
    <Table className={classes.table}>
      <TableBody>
        {isFetchingData || funnelReport === null ? (
          <>
            <SkeletonRow size={3} delay={0} />
            <SkeletonRow size={3} delay={0.2} />
            <SkeletonRow size={3} delay={0.4} />
          </>
        ) : (
          ROWS.map(({ id, label }) => (
            <TableRow key={id} className={classes.row}>
              <TableCell className={classes.cell}>{label}</TableCell>
              <TableCell className={classes.cell}>{funnelReport[id]}</TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
}
