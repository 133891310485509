import { type RevenueReport } from '@witmetrics/api-client';
import { Dictionary } from '@/types';
import { theme } from '@/utils/theme';
import { exportArraysToSpreadsheet } from '@/utils/spreadsheets';
import { buildExportTimestamp } from '@/utils/time';

export const classes = {
  wrapper: 'flex justify-between max-w-[800px] px-6 py-8',
  cell: 'py-2',
  stickyCell: 'py-2 sticky left-0 bg-white border-r border-grey-3',
  summaryCell: 'py-2 font-semibold',
  loadingMessage:
    'h-full w-full flex justify-center items-center text-2xl font-light text-grey-6 p-8',
};

export const styles = {
  tableBody: {
    '& .MuiTableRow-root:last-child td.sticky': {
      borderRight: `1px solid ${theme.palette['grey-3'].main}`,
    },
    '& .MuiTableRow-hover:hover': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(2):hover) tr > *:nth-of-type(2)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(3):hover) tr > *:nth-of-type(3)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(4):hover) tr > *:nth-of-type(4)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(5):hover) tr > *:nth-of-type(5)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(6):hover) tr > *:nth-of-type(6)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(7):hover) tr > *:nth-of-type(7)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(8):hover) tr > *:nth-of-type(8)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(9):hover) tr > *:nth-of-type(9)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(10):hover) tr > *:nth-of-type(10)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(11):hover) tr > *:nth-of-type(11)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(12):hover) tr > *:nth-of-type(12)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(13):hover) tr > *:nth-of-type(13)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '&:has(tr > *:nth-of-type(14):hover) tr > *:nth-of-type(14)': {
      backgroundColor: theme.palette['grey-2'].main,
    },
  },
};

type LeadsPerformance = RevenueReport['data'][number]['leads'];

type ReportByMonth = Dictionary<LeadsPerformance>;

type ExportRow = {
  label: string;
  jan: number | string;
  feb: number | string;
  mar: number | string;
  apr: number | string;
  may: number | string;
  jun: number | string;
  jul: number | string;
  aug: number | string;
  sep: number | string;
  oct: number | string;
  nov: number | string;
  dec: number | string;
  total: number | string;
};

export function groupByMonth(report: RevenueReport): LeadsPerformance[] {
  let result: ReportByMonth = {};
  report.data.forEach(({ startDate, leads }) => {
    const month = new Date(startDate).getMonth();
    if (!result[month]) {
      result[month] = leads;
    } else {
      result[month].treatmentOutstanding += leads.treatmentOutstanding;
      result[month].treatmentAccepted += leads.treatmentAccepted;
      result[month].revenue.value += leads.revenue.value;
    }
  });
  return Object.keys(result)
    .sort()
    .map((key) => result[key]);
}

export function sumReportValue(
  report: LeadsPerformance[],
  key: keyof LeadsPerformance
) {
  return key === 'revenue'
    ? report.reduce((acc, curr) => acc + curr[key].value, 0)
    : report.reduce((acc, curr) => acc + curr[key], 0);
}

export function exportReport(
  currentReport: RevenueReport | null,
  previousReport: RevenueReport | null
) {
  if (!currentReport || !previousReport) return null;
  const currentData = groupByMonth(currentReport);
  const previousData = groupByMonth(previousReport);
  return exportArraysToSpreadsheet({
    data: [
      buildTitleRow(2024),
      buildExportRow('Patients (Accepted)', currentData, 'treatmentAccepted'),
      buildExportRow(
        'Patients (Outstanding)',
        currentData,
        'treatmentOutstanding'
      ),
      buildExportRow('Revenue', currentData, 'revenue'),
      {},
      buildTitleRow(2023),
      buildExportRow('Patients (Accepted)', previousData, 'treatmentAccepted'),
      buildExportRow(
        'Patients (Outstanding)',
        previousData,
        'treatmentOutstanding'
      ),
      buildExportRow('Revenue', previousData, 'revenue'),
    ],
    sheetName: 'Sales Overview',
    filename: `witmetrics-sales_overview_${buildExportTimestamp()}`,
  });
}

function buildTitleRow(year: number) {
  return [
    `${year}`,
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Total',
  ];
}

function buildExportRow(
  label: string,
  rowData: LeadsPerformance[],
  key: keyof LeadsPerformance
) {
  return [
    label,
    ...rowData.map((data) => formatColumnValue(data, key)),
    sumReportValue(rowData, 'treatmentAccepted'),
  ];
}

function formatColumnValue(
  value: LeadsPerformance,
  key: keyof LeadsPerformance
) {
  return key === 'revenue' ? value[key].value : value[key];
}
