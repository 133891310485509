import { type RevenueReport } from '@witmetrics/api-client';
import Chart from '@/components/Chart';
import { formatReport } from './utils';

export type AcceptedPatientsChartProps = {
  currentReport: RevenueReport;
  previousReport: RevenueReport;
};

export default function AcceptedPatientsChart({
  currentReport,
  previousReport,
}: AcceptedPatientsChartProps) {
  const reportData = formatReport(currentReport, previousReport);
  return (
    <Chart
      type="line"
      width="100%"
      height="250px"
      options={reportData.options}
      series={reportData.series}
    />
  );
}
