import { type ApexOptions } from 'apexcharts';

export const DEFAULT_CHART_OPTIONS: ApexOptions = {
  chart: {
    animations: {
      // @ts-ignore TODO
      easing: 'easeinout',
    },
  },
  states: {
    hover: {
      filter: {
        type: 'lighten',
        // @ts-ignore TODO
        value: 0,
      },
    },
    active: {
      allowMultipleDataPointsSelection: false,
      filter: {
        type: 'darken',
        // @ts-ignore TODO
        value: 0.35,
      },
    },
  },
};
