import { API } from '@/api';
import { getLastYear, getYearToDate } from '@/utils/time';
import { TIME_PERIODS } from '@/constants/timePeriods';

export const classes = {
  wrapper:
    'w-full h-full max-w-[1600px] min-w-[700px] flex flex-col pl-12 pr-8 py-8 overflow-auto',
  section: 'my-3',
};

export const ROW_SPACING = 3;

export const COLUMN_SPACING = 3;

export function fetchRevenueReport(practiceID: number) {
  const thisYear = getYearToDate();
  const lastYear = getLastYear();
  const params = {
    filters: [],
    split: [],
    timestamp: new Date().toISOString(),
    timePeriod: TIME_PERIODS.MONTH,
  };
  return Promise.all([
    API.Reports.fetchRevenueReport(practiceID, {
      startDate: thisYear[0],
      endDate: thisYear[1],
      ...params,
    }),
    API.Reports.fetchRevenueReport(practiceID, {
      startDate: lastYear[0],
      endDate: lastYear[1],
      ...params,
    }),
  ]);
}
